






































import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import Auth from "@aws-amplify/auth";
import Vue from "vue";
export default Vue.extend({
    data() {
        return {
            unsubscribeAuth: undefined as any,
            formFields: [
                {
                    type: "email",
                    label: "Email Address *",
                    placeholder: "Enter your email address",
                    inputProps: { required: true, autocomplete: "username" },
                },
                {
                    type: "password",
                    label: "Password *",
                    placeholder: "Enter your password",
                    inputProps: {
                        required: true,
                        autocomplete: "new-password",
                    },
                },
            ],
        };
    },
    created() {
        this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
            if (authState === AuthState.SignedIn) {
                this.$store.commit("auth/setAuthState", authState);
                this.$store.commit("auth/setUser", authData);
                this.$router.push("/profile");
            }
        });
    },
    methods: {
        async signIn() {
            // Auth.si
        },
        async onLineLogin() {
            Auth.federatedSignIn({ customProvider: "LINE" });
        },
    },
    beforeDestroy() {
        this.unsubscribeAuth();
    },
});
